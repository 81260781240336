import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import PopModelling from './Pages/PopModelling';
import SterilizationGrowth from './Pages/SterilizationGrowth';
import SterilizationMaintenance from './Pages/SterilizationMaintenance';
import NavButton from './NavButton';
import NavBar from './NavBar';
import DebugOverlay from './DebugOverlay';
import {tabs} from "./Constants";
import Sidebar from './Sidebar';
import VariableDump from './VariableDump';
import { FinalValueSterilizationGrowth, FinalValueSterilizationMaintenance, PopModelChartData } from './Helpers';
import HelpButtonAndModal from './HelpButtonAndModal';
import { SettingsProvider, useSettings } from './contexts/SettingsContext';
import { useTheme } from '@emotion/react';
import { useDocumentManagement } from './contexts/DocumentManagementContext';
import { useChartService } from './contexts/ChartServiceContext';

// import SterilizationGrowth from './Pages/SterilizationGrowth';
// import SterilizationMaintenance from './Pages/SterilizationMaintenance';
// const defaultVariables = {
//   desired_S_N: 0.5,    // Proportion of sterilized dogs
//   t0: 0,            // Initial time  
//   initial_pop: 2000,         // Initial total population  
//   duration_t: 18,    // Time over which to model (in months)  
//   h: 1,             // Step size for Runge Kutta method (1 recommended)  
//   k: 10000,         // Carrying capacity of environment  
//   prop_female: 0.5,         // Proportion of population that are female (0-1)  
//   adult_surv: 0.99,        // Adult survivability rate (0-1)  
//   infant_surv: 0.4,         // Infant survivability rate (0-1)  
//   l: 6,             // Average litter size  
//   avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
//   initial_ster_prop: 0,            // Initial number of sterilised dogs  
//   m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
//   n_s: 250,         // Number of sterilisations taking place each month  
    
//   // N_t               // Total population at time t  
//   // S_t               // Number of sterilised dogs at time t    
// }

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}
const layoutBreak = 650;

function App() {


  // The management for this application falls into DocumentManagementContext
  const {updateVariables,activeVariableSetMemo} = useChartService();

  const {setActiveDocumentState} = useDocumentManagement();

  // const [variables, setVariables] = useState(activeVariableSetMemo)
  const [prevVariables, setPrevVariables] = useState(activeVariableSetMemo)
  const [chartData,setChartData] = useState({x: [], c1: [], c2: [], c3: []})
  const [prevChartData, setPrevChartData] = useState({x: [], c1: [], c2: [], c3: []})


  const [nsValue, setNSValue] = useState(0)
  const [isMobile, setIsMobile] = useState(window.innerWidth < layoutBreak)

  // This should be calculated
  const createBaseIntervention = () => {
    return {
      desired_S_N: 0.5,    // Proportion of sterilized dogs
      t0: 0,            // Initial time  
      initial_pop: 2000,         // Initial total population  
      duration_t: 18,    // Time over which to model (in months)  
      h: 1,             // Step size for Runge Kutta method (1 recommended)  
      k: 10000,         // Carrying capacity of environment  
      prop_female: 0.5,         // Proportion of population that are female (0-1)  
      adult_surv: 0.99,        // Adult survivability rate (0-1)  
      infant_surv: 0.4,         // Infant survivability rate (0-1)  
      l: 6,             // Average litter size  
      avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
      initial_ster_prop: 0,            // Initial number of sterilised dogs  
      m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
      n_s: 250,         // Number of sterilisations taking place each month  
    }
  }

  // const onAddIntervention = () => {
  //   updateVariables({
  //     ...activeVariableSetMemo,
  //     interventions: [
  //       ...activeVariableSetMemo.interventions,
  //       createBaseIntervention()
  //     ]
  //   })
  // }

  /* When variables are changed on the input, set the pending ones 
    Pending => Current
    Current => Previous
  */
  const onChangeVariables = (set, key, value) => {
    // setPendingVariables({...pendingVariables, [key]: value});
    // console.log(activeDocumentState);
    if(set == 'initial') {

      

      updateVariables({
        ...activeVariableSetMemo,
        initial: {
          [key]: value
        }
      });
    } else {

      // Update the deep keys of the interventions
      updateVariables({
          ...activeVariableSetMemo,
          interventions: activeVariableSetMemo.interventions.map((intervention, index) =>
              index === set
                  ? { ...intervention, [key]: value }
                  : intervention
          )
      });
    }

    // Update our master doc's state. If this is a dirty update, we should see that flag in activeDocUpdated
    setActiveDocumentState(activeVariableSetMemo);
    // updateVariables({...activeDocumentState,[key]: value});
  }
  // Calcaulate the chart as soon as the page loads
  useEffect( () => {
    onCalculate();
  }, []);

  const handleResize = ( () => {
    setIsMobile(window.innerWidth < layoutBreak)
  });
  
  // When the window resizes, do stuff
  useEffect(() => {
    window.addEventListener(
        "resize", 
        handleResize,
        false
    );
  }, []);
  
  const theme = useTheme();

  // When "Calculate" is clicked, build the graph, shift values
  const onCalculate = () => {
    setPrevVariables(activeVariableSetMemo);

    const xAxisChanged = activeVariableSetMemo.t0 != prevVariables.t0 || 
          activeVariableSetMemo.h != prevVariables.h || 
          activeVariableSetMemo.duration_t != prevVariables.duration_t; // Used in setPrevChartData to decide whether or not we burn the previous chart to prevent X axes from being incompatible
// Contact the server
  }

  return (
      <SettingsProvider>
        <div className="App" style={{background: theme.mainBG}}>
          <Box 
            display='flex'
            flexDirection={'column'} 
            height="100%" 
            maxHeight={'100%'} 
          > {/* App */}
            <Box className="AppNav-Container"> {/* Header */}
              <NavBar isMobile={isMobile} />
            </Box>
            
            <Box
                className="AppBody-Container" 
                sx={{
                overflow: 'hidden', 
                display:'flex', 
                displayPrint:'block'
              }} 
              style={{flex: 1,  height: '100%'}}
            > {/* App Body */}
              
                <PopModelling isMobile={isMobile}
                              chartData={chartData} 
                              prevChartData={prevChartData} 
                              prevVariables={prevVariables} 
                ></PopModelling>
              {
                <Sidebar
                        isMobile={isMobile} 
                />
              }
            </Box>
            <Box> {/** Variable Summary */}
              {/* <VariableDump variables={variables}/> */}
            </Box>
          </Box>
          
          {
            <HelpButtonAndModal />
          }

          {/* <Box textAlign="center" padding={5} style={{background:theme.contrast, color:theme.contrastText}}>
              Built with love by Alexandra Hammond and Quintan Neville.
          </Box> */}
        </div>

          <DebugOverlay/>
        </SettingsProvider>

  );
}

export default App;
