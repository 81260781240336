import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Button, Card, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemText, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import PopModelling from './Pages/PopModelling';
import SterilizationGrowth from './Pages/SterilizationGrowth';
import SterilizationMaintenance from './Pages/SterilizationMaintenance';
import NavButton from './NavButton';
import NavBar from './NavBar';
import {tabs} from "./Constants";
import Sidebar from './Sidebar';
import VariableDump from './VariableDump';
import { FinalValueSterilizationGrowth, FinalValueSterilizationMaintenance, PopModelChartData } from './Helpers';
import HelpButtonAndModal from './HelpButtonAndModal';
import { SettingsProvider, useSettings } from './contexts/SettingsContext';
import { useTheme } from '@emotion/react';
import { useDocumentManagement } from './contexts/DocumentManagementContext';
import { CheckBoxOutlined, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

const DebugOverlay = () =>{
    const VISIBLE = false;

    // Return prematurely if the overlay is not visible
    
    const {documentIsLoading, documentIsSaving, documentChanged } = useDocumentManagement();
    // console.log(documentChanged, documentIsLoading(), documentIsSaving())
    if (!VISIBLE) return <div/>;
    return (
        <Box style={{position: 'fixed', left: 10, bottom: 10}}>
            <Card style={{height: 'auto', width: '300px'}}>
                <CardHeader title="Debug Overlay" />
                <CardContent>
                    <List>
                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                {
                                    documentChanged ? 
                                        <CheckBoxOutlined/>
                                        :
                                        <RadioButtonUnchecked/>
                                }
                            </ListItemAvatar>
                            <ListItemText>Modified</ListItemText>
                        </ListItem>


                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                {
                                    documentIsSaving() ? 
                                        <CheckBoxOutlined/>
                                        :
                                        <RadioButtonUnchecked/>
                                }
                            </ListItemAvatar>
                            <ListItemText>Saving</ListItemText>
                        </ListItem>

                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                {
                                    documentIsLoading() ? 
                                        <CheckBoxOutlined/>
                                        :
                                        <RadioButtonUnchecked/>
                                }
                            </ListItemAvatar>
                            <ListItemText>Loading</ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Box>
    )
}

export default DebugOverlay;
