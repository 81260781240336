import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Fade, FormControlLabel, Menu, MenuItem, MenuList, Box, Switch, Typography, Stack, Chip, OutlinedInput, FilledInput, IconButton, Tooltip } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { convert_to_title, ensureFloat, ensureInt, interventionContentByType } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { intervention_types, tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings} from "./contexts/SettingsContext";
import VariableSet from "./VariableSet";
import { AddCircleOutline, Delete, ExpandMore, KeyboardArrowUp, KeyboardArrowUpSharp } from "@mui/icons-material";
import InterventionVariables from "./InterventionVariables";
import { useState } from "react";
import { useDocumentManagement } from "./contexts/DocumentManagementContext";
import { useChartService } from "./contexts/ChartServiceContext";

const InterventionBlock = ({intervention, index}) => {
    const {activeVariableSetMemo, updateVariables} = useChartService();

    const interventions = activeVariableSetMemo.interventions;
    const constraintsForType = variableSet?.intervention[intervention.type];
    const constraint_keys = Object.keys(constraintsForType);

    const handleChangeIntervention = (index, key, value) => {
        const newIntervention = { ...interventions[index], [key]: value };

        const updatedInterventions = [...interventions]; // Create a copy of the interventions array
        updatedInterventions[index] = newIntervention; // Replace the item at the specific index
    
        updateVariables({
            ...activeVariableSetMemo,
            interventions: updatedInterventions,
        });

    }

    const handleDeleteIntervention = (index) => {
        const updatedInterventions = [...interventions]; // Create a copy of the interventions array
        updatedInterventions.splice(index, 1); // Remove the item at the specific index
    
        updateVariables({
            ...activeVariableSetMemo,
            interventions: updatedInterventions,
        });

    }

    return(
        
        <Accordion
            onChange={(e, state) => console.log("CHANGED", e, state)}
        >
                
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Stack direction="row" width={"100%"} alignItems="center" justifyContent="space-between">
                    <Box flex={1}>
                        <Typography variant="body2">{convert_to_title(intervention.type)}</Typography>
                        <Typography variant="caption" style={{opacity: intervention.note ? 1 : 0.5}}>{`${intervention.note ? intervention.note : "Add a note"}`}</Typography>
                    </Box>
                    <Box><Chip label={`@ Month ${intervention.intervention_start}`}/></Box>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="row" width={"100%"} alignItems="center" justifyContent="space-between">
                    <Box flex={1}>
                        <FilledInput
                            fullWidth 
                            placeholder="Intervention Note"
                            value={intervention.note} 
                            onChange={(e) => handleChangeIntervention(index, 'note', e.target.value)}
                            label="Intervention Note"
                        />
                    </Box>
                    <Box>
                        <Tooltip title="Delete Intervention">
                            <IconButton  color="primary" onClick={() => handleDeleteIntervention(index)}><Delete/></IconButton>
                        </Tooltip>
                    </Box>
                </Stack>
                
                {
                    constraint_keys
                    .map( (v) => {

                        return (<Input
                            key={"varset_initial_"+v}
                            hasSlider={true}
                            varKey={"varsset_"+v}
                            label={constraintsForType[v].label}
                            value={intervention[v]}
                            onChange={(e) => handleChangeIntervention(index, v, e)}//handleChangeVariable(key, e)}//handleChangeVariable(key, e))}
                            disabled={false} // TODO: Add this back in
                            max={constraintsForType[v].max(activeVariableSetMemo, intervention)}
                            min={constraintsForType[v].min(activeVariableSetMemo, intervention)}
                            marks={false}
                            stepSize={constraintsForType[v].stepSize}
                        /> )
                    })}
                
            </AccordionDetails>
        </Accordion>
    
    );
}

export default InterventionBlock;