import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Fade, FormControlLabel, Menu, MenuItem, MenuList, Paper, Switch, Typography } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { ensureFloat, ensureInt } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings} from "./contexts/SettingsContext";
import VariableSet from "./VariableSet";
import { AddCircleOutline, ExpandMore, KeyboardArrowUp, KeyboardArrowUpSharp } from "@mui/icons-material";
import InterventionVariables from "./InterventionVariables";
import { useState } from "react";
import Interventions from "./Interventions";
import { useDocumentManagement } from "./contexts/DocumentManagementContext";
import { useChartService } from "./contexts/ChartServiceContext";

const SidebarContent = ({isMobile, onAddIntervention, variables, currentTab, onChangeVariables, calculatedNSValue}) => {
    const {setSetting , settings} = useSettings();
    const {loadChartData} = useChartService();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOnCalculate = () => {
        loadChartData();
    }

    const {activeVariableSetMemo} = useChartService();
    const docVars = activeVariableSetMemo;
    
    // const handleSettingsChange = (event) => {
    //     setSetting(event.target.name, event.target.checked);
    // };

    // const hideInterventionPopover = () => {
    //     setAnchorEl(null);
    // }

    // const showInterventionPopover = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleAddIntervention = (type) => {
    //     console.log(type)
    //     onAddIntervention(type);
    //     hideInterventionPopover();
    // }
    

    return(
        <>
        <Box className="sidebar-container" padding={2}>
                <ThemedButton 
                    onClick={() => handleOnCalculate()}
                    style={
                        isMobile ?
                        {border:'none', width:'calc(100% - 80px)', border: "2px solid white", boxShadow:'1px 3px 10px rgba(0,0,0,.10)'} :
                        {border:'none', width:'100%', border: "2px solid white", boxShadow:'1px 3px 10px rgba(0,0,0,.10)'}
                    } 
                    color={primary}>
                            Calculate
                </ThemedButton>
            </Box>

            <Paper sx={{
                    display: 'flex', 
                    gap: '20px', 
                    position: 'relative',
                    flexDirection:'column', 
                    padding:'30px 20px', 
                    background: colors.sidePanel,
                    overflowY: 'scroll',
                    flex: 1,
                }}>
                

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="variables-initial-condiitons"
                        id="variables-initial-condiitons"
                    >
                        Initial Conditions
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            docVars?.initial != null &&
                            <VariableSet
                            omitVariables={[]}
                            values={variables}
                            onChangeVariables={(key, value) => onChangeVariables('initial', key, value)}
                            />
                        }
                    </AccordionDetails>
                </Accordion>
                {
                    docVars?.interventions != null &&
                    <Interventions/>
                }


                {/*
                <Box>
                {
                        variables.interventions.map( (intervention, index) =>
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`variables-intervention-${index}`}
                                        id={`variables-intervention-${index}`}
                                    >
                                        Intervention {index+1}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <InterventionVariables
                                            omitVariables={[]}
                                            variables={intervention}
                                            onChangeVariables={(key, value) => onChangeVariables(index, key, value)}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                )} *}
                </Box>
                {/* <Box>
                //     <Typography variant="caption">Prefs</Typography>
                //     <Box>
                //         <FormControlLabel
                //             control={
                //                 <Switch checked={settings.showVariableKeys} onChange={handleSettingsChange} name="showVariableKeys" />
                //             }
                //             label="Show Variables"
                //         />
                //     </Box>

                // </Box> */}

            </Paper>
        </>
    );
}

export default SidebarContent;