import { Avatar, Box, Button, ButtonGroup, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import NavButton from "./NavButton";
import { colors, secondary, theme } from "./Theming";
import {getTabName, tabs} from "./Constants";
import { ArrowDropDown, Menu } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProfileButton = ({setTab, currentTab, isMobile}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
  
    const handleClick = () => {
    };
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
    const {user, isAuthenticated, logout} = useAuth0();


    const handleSignOut = () => {
        logout();
    }
    return (
        <>
            <ButtonGroup
                color="secondary"
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                <Button onClick={handleClick}>
                    <Avatar 
                        src={user.picture} 
                        alt={user.name} 
                        mr={2}   sx={{ marginRight: 2, width: 24, height: 24 }} 
                    />
                    {isMobile ? '' : `Hello ${user.given_name}`} 
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                                <MenuItem
                                    key={'menu-profile'}
                                >
                                    Profile
                                </MenuItem>
                                <MenuItem
                                    key={'menu-hub'}
                                >
                                    Data Hub
                                </MenuItem>
                                <MenuItem
                                    key={'menu-signout'}
                                    onClick={() => handleSignOut()}
                                >
                                    Sign Out
                                </MenuItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default ProfileButton;