import { Box, Button, CircularProgress, Divider, Fab, Input, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../AnimatedModal";
import { useEffect, useState } from "react";
import { useChartService } from "../contexts/ChartServiceContext";
import { useDocumentManagement } from "../contexts/DocumentManagementContext";

const SaveAsModal
 = ({open=false, onClose=() => {}, onSave}) => {

    const {activeDocumentInstance, setActiveDocumentInstance, validateDocumentName} = useDocumentManagement();


    const [canSave, setCanSave] = useState(false);
    const [loading, setLoading] = useState(true);

    const nameIsLongEnough = activeDocumentInstance.name.length > 5
    

    const handleValidateName = () => {
        setLoading(true)

        validateDocumentName(activeDocumentInstance.name, () => {
            setCanSave(true);
            setLoading(false);
        }, () => {
            setCanSave(false);
            setLoading(false);
        })
    }

    useEffect(() => {
        handleValidateName();
    },[]);


    const handleSetDocName = (e) => {
        const result = {...activeDocumentInstance, name: e.target.value};
        console.log(result)
        setActiveDocumentInstance(result)
        handleValidateName();
    }

    return (
        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="Save new model"
        >
            <Stack spacing={1}>
                <TextField value={activeDocumentInstance.name} onChange={ handleSetDocName } id="outlined-basic" label="Add a name here..." variant="outlined" />
                {
                    !nameIsLongEnough &&
                    <Box>
                        <Typography variant="body2" color={'red'}>Name is too short.</Typography>
                    </Box>

                }
                {
                    !canSave && !loading &&
                    <Box>
                        <Typography variant="body2" color={'red'}>Name already exists. Please choose another.</Typography>
                    </Box>
                }
                <Button disabled={loading || !canSave} onClick={() => onSave()} variant="contained" fullWidth>
                    {
                        loading ? <CircularProgress sx={{color:"#ffffff"}}/> : "Save"
                    }
                </Button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
            </Stack>
        </AnimatedModal>
    )
}

export default SaveAsModal;
