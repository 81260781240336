import { Box, Divider, FormControlLabel, Paper, Switch, Typography } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { ensureFloat, ensureInt } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings } from "./contexts/SettingsContext";
import { useMemo } from "react";
import { useChartService } from "./contexts/ChartServiceContext";
import { useDocumentManagement } from "./contexts/DocumentManagementContext";

const VariableSet = ({ isMobile }) => {

    const {activeVariableSetMemo, updateVariables} = useChartService();

    const values = activeVariableSetMemo;

    const { setSetting, settings } = useSettings();

    const initialValues = useMemo(() => values?.initial, [values?.initial]);

    const variableSetInitial = variableSet.initial;

    const handleSettingsChange = (event) => {
        setSetting(event.target.name, event.target.checked);
    };

    const handleChangeVariable = (key, value) => {
        let v = value;
        if(variableSetInitial[key].type == 'int') {
            v = ensureInt(key, value);
        }
        else if(variableSetInitial[key].type == 'float') {
            v = ensureFloat(key, value);
        }

        updateVariables({
            ...activeVariableSetMemo,
            initial: {
                ...activeVariableSetMemo.initial,
              [key]: value
            }
        });
    }

    const keys = useMemo(() => Object?.keys(initialValues), [initialValues]);

    return (
        <Box>
            {keys.map((key) => {
                if(variableSetInitial?.[key]) {
                    return (
                        <Input
                            key={"varset_initial_"+key}
                            hasSlider={true}
                            varKey={"varsset_"+key}
                            label={variableSetInitial[key].label}
                            value={initialValues[key]}
                            // onChange={handleSettingsChange}
                            onChange={(e) => handleChangeVariable(key, e)}//handleChangeVariable(key, e))}
                            disabled={false} // TODO: Add this back in
                            max={variableSetInitial[key].max(initialValues)}
                            min={variableSetInitial[key].min(initialValues)}
                            stepSize={variableSetInitial[key].stepSize ? variableSetInitial[key].stepSize : 1}
    
                        />
                    )

                } else {
                    {/* Condition met when variables may be loading */}
                    <div></div>
                }
            })}
        </Box>
    )
};

export default VariableSet;