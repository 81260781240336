import { Box, Button, Buttonn, CircularProgress, Divider, Fab, Input, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Pagination, Paper, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../AnimatedModal";
import { useState } from "react";
import { AutoGraphOutlined, DocumentScanner } from "@mui/icons-material";
import Moment from "react-moment";
const HumanTimestamp = (mysqlDate) => {

    return (
        <Moment fromNow>{mysqlDate}</Moment>
    )
}

export default HumanTimestamp;