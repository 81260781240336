import { styled } from '@mui/system';
import { ButtonBase, buttonClasses } from '@mui/material';

const primary = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const secondary = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const ThemedButton = styled(ButtonBase)(
  ({ theme, color=primary }) => {
  return(`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${color.neutral};
  padding: 8px 16px;
  border-radius: 100px;
  color: ${color.text};
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${color.neutral};
  
  &:hover {
    background-color: ${color.hover};
  }

  &.${buttonClasses.active} {
    background-color: ${color.press};
    box-shadow: none;
    transform: scale(0.99);
  }

  &.${buttonClasses.focusVisible} {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? color[300] : color[200]};
    outline: none;
  }

  &.${buttonClasses.disabled} {
    background-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[700]};
    border: 0;
    cursor: default;
    box-shadow: none;
    transform: scale(1);
  }
  `)},
);

export default ThemedButton;