import { Box, Button, Divider, Fab, Input, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../AnimatedModal";

const DiscardModal
 = ({open=false, onDiscard, onOpenDoc, onSave, onClose=() => {}}) => {

    const handleOpenDoc = (saveFirst) => {
        if( saveFirst ) {
            onSave()
        } else {
            onSave()
        }
    }
    return (
        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="Discard changes?"
        >
            <Stack spacing={2}>
                <Typography>Your document has been changed without a save. Are you sure you want to continue?</Typography>
                {/* <Button variant="contained" fullWidth>Save and Continue</Button> */}
                <Button variant="text"  onClick={() => onDiscard()} fullWidth>Discard and Continue</Button>
                <Button onClick={() => onClose()} variant="nah" fullWidth>Cancel</Button>
            </Stack>
        </AnimatedModal>
    )
}

export default DiscardModal;
