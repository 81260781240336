import { Box, Button, Divider, Fab, Input, Modal, Paper, Stack, TextField, Typography } from "@mui/material";

import { useState } from "react";
import AnimatedModal from "../AnimatedModal";
import { useDocumentManagement } from "../contexts/DocumentManagementContext";

const SaveModal = 
({open=false, onClose, onSave}) => {

    const {activeDocumentInstance, setActiveDocumentInstance} = useDocumentManagement();

    const handleSetDocName = (e) => {
        const result = {...activeDocumentInstance, name: e.target.value};
        console.log(result)
        setActiveDocumentInstance(result)
    }

    return (
        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="Save new model"
        >
            <Stack spacing={1}>
                <TextField value={activeDocumentInstance.name} onChange={ handleSetDocName } id="outlined-basic" label="Add a name here..." variant="outlined" />
                <Button onClick={() => onSave()} variant="contained" fullWidth>Save</Button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
            </Stack>
        </AnimatedModal>
    )
}

export default SaveModal;