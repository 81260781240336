import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Fade, FormControlLabel, Menu, MenuItem, MenuList, Box, Switch, Typography, FormGroup, Stack, Checkbox } from "@mui/material";
import NavButton from "./NavButton";
import { colors, primary, secondary } from "./Theming";
import Input from "./Input";
import { convert_to_title, ensureFloat, ensureInt, interventionContentByType } from "./Helpers";
import ThemedButton from "./ThemedButton";
import { intervention_types, tabs, variableSet } from "./Constants";
import GradientBlock from "./GradientBlock";
import { useSettings} from "./contexts/SettingsContext";
import VariableSet from "./VariableSet";
import { AddCircleOutline, ExpandMore, KeyboardArrowUp, KeyboardArrowUpSharp } from "@mui/icons-material";
import InterventionVariables from "./InterventionVariables";
import { useState } from "react";
import { useDocumentManagement } from "./contexts/DocumentManagementContext";
import InterventionBlock from "./InterventionBlock";
import { useChartService } from "./contexts/ChartServiceContext";

const Interventions = ({isMobile, onCalculate, currentTab, onChangeVariables, calculatedNSValue}) => {
    const {setSetting , settings} = useSettings();
    const {activeVariableSetMemo, updateVariables} = useChartService();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleSettingsChange = (event) => {
        setSetting(event.target.name, event.target.checked);
    };

    const hideInterventionPopover = () => {
        setAnchorEl(null);
    }

    const showInterventionPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddIntervention = (type) => {
        // onAddIntervention(type);

        // Add the intervention to the document
        updateVariables({
            ...activeVariableSetMemo,
            interventions: [
                ...activeVariableSetMemo.interventions,
              {
                type: type,
                ...interventionContentByType(type, activeVariableSetMemo)
              }
            ]
        });


        hideInterventionPopover();
    }
    

    return(
        
        <Box>
            <Box><Typography variant="caption">Interventions</Typography></Box>
            {/* List of Interventions */}
            {
                activeVariableSetMemo.interventions
                .sort((a,b) => a.intervention_start - b.intervention_start)
                .map((intervention, i) => {
                    return (
                        <InterventionBlock
                            index={i}
                            key={`intervention_${i}`}
                            intervention={intervention}
                        />
                    )
                })
            }

            <Button
                fullWidth
                variant="contained"
                onClick={showInterventionPopover}
                startIcon={<AddCircleOutline />}
                endIcon={<KeyboardArrowUpSharp />}
                color='secondary'
            >
                Add Intervention
            </Button>

            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                style={{
                    width: '100%',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
          
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={hideInterventionPopover}
                // TransitionComponent={Fade}
            >
                {
                    intervention_types.map((type) =>
                        <MenuItem key={`new_intervention_type_${type}`} onClick={()=>handleAddIntervention(type)}>{convert_to_title(type)}</MenuItem>
                    )
                }
            </Menu>

            <Stack spacing={1} style={{marginTop:'20px'}}>
                <Box><Typography variant="caption">Settings</Typography></Box>
                <Typography></Typography>
                <FormGroup>
                    <FormControlLabel p={2} control={<Checkbox value={settings.showPreviousGraph} onClick={() => setSetting('showPreviousGraph',!settings.showPreviousGraph)} />} label="Show previous graph run" />
                </FormGroup>
                <Divider/>
            
            </Stack>
        </Box>
    
    );
}

export default Interventions;