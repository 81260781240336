import { Box, Button, Buttonn, CircularProgress, Divider, Fab, FormGroup, IconButton, Input, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Pagination, Paper, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../AnimatedModal";
import { useEffect, useState } from "react";
import { AutoGraphOutlined, Delete, DocumentScanner, HighlightOff, RemoveCircle, Search, StopCircle } from "@mui/icons-material";
import HumanTimestamp from "../shared/HumanTimestamp";
import { useChartService } from "../contexts/ChartServiceContext";
import { useDocumentManagement } from "../contexts/DocumentManagementContext";
const OpenModal = ({onChooseDocument, open=false, onClose=() => {}}) => {

    const {documentsAvailable, isLoading, openOpenModal } = useDocumentManagement();

    const [searchTerm, setSearchTerm] = useState('');

    const onChangeSearchTerm = (val) => {
        setSearchTerm(val);
        openOpenModal(val);
    }

    useEffect(() => {
        if(open == true) {
            openOpenModal('');
            setSearchTerm('');
        }
    },[open]);

    return (
        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="Open Model"
        >
        <Stack spacing={4}>
            <Stack direction="row" style={{alignItems:'center'}} spacing={1}>
                <Search/>
                <TextField placeholder="Search..." variant="standard" style={{flex:'1'}} type='search' value={searchTerm} onChange={e => onChangeSearchTerm(e.target.value)}/>
            </Stack>
            <Box style={{textAlign:'center'}}>
                {
                    isLoading == true &&
                    <Box>
                        <CircularProgress/>
                    </Box>
                }

                {
                    documentsAvailable.length == 0 &&
                    <Typography variant="body2" component="body2" gutterBottom>
                        {
                        searchTerm.length > 3 ?
                            "No match for this search"
                            :
                            "No documents available"
                        }
                    </Typography>
                }
            </Box>
            {
                documentsAvailable.length > 0 && isLoading == false &&
                <>
                    <List style={{marginTop: 0}}>
                        {
                            documentsAvailable.map( (d) => 
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => onChooseDocument(69)}
                                    >
                                    <ListItemIcon>
                                        <AutoGraphOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={d.name} secondary={<HumanTimestamp mysqlTimestamp={d.created_at}/>} />
                                    </ListItemButton>
                                </ListItem>

                            )
                        }

                    </List>
                    <Box justifyItems={'center'}>
                        <Stack spacing={2} alignItems="center">
                            <Pagination  page={documentsAvailable.page} count={documentsAvailable.total_pages} color="secondary" />
                        </Stack>
                    </Box>
                </>
            }

        </Stack>
    </AnimatedModal>
    )
}

export default OpenModal;