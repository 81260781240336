import { Avatar, Box, Button, ButtonGroup, Card, CardContent, CardMedia, CircularProgress, ClickAwayListener, Container, createTheme, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, Typography } from "@mui/material";
import NavButton from "./NavButton";
import {getTabName, tabs} from "./Constants";
import { ArrowDropDown, Menu } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import { primary, secondary, theme } from './Theming';
import { ThemeProvider } from "@emotion/react";
import image from "./images/backdrop.jpg";
import { DocumentManagementProvider } from "./contexts/DocumentManagementContext";
import { ChartServiceProvider } from "./contexts/ChartServiceContext";
import axios from "axios";


const defaultDocDetails = {
    id:          null,
    name:        'New Model', // String(256)
    updated_at:  null,        // datetime
    created_at:  null,        // datetime
    json_state:  null,        // Document default state	- override by setting this at time of save
    app_slug:    null,        // Accessor for doc within app
    json_extras: null,        //	Extra non-document stuff to save - future proofing - Can be null
    organization_id: null,	  // Get current user's org when saving
    created_by_user_id: null  // Current user's id when saving 
  
}
  
const defaultDocState = {
        initial: {
          start_month: 0,            // Initial time  
          initial_pop: 2000,         // Initial total population  
          duration_t: 18,    // Time over which to model (in months)  
          step_size: 1,             // Step size for Runge Kutta method (1 recommended)  
          carry_cap: 10000,         // Carrying capacity of environment  
          prop_female: 0.5,         // Proportion of population that are female (0-1)  
          adult_surv: 0.99,        // Adult survivability rate (0-1)  
          infant_surv: 0.4,         // Infant survivability rate (0-1)  
          avg_litter_size: 6,             // Average litter size  
          avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          initial_ster_prop: 0,            // Initial number of sterilised dogs  
          net_migration_prop: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          monthly_sters: 250,         // Number of sterilisations taking place each month  
        },
        interventions: [
          // {
          //   desired_S_N: 0.5,    // Proportion of sterilized dogs
          //   t0: 0,            // Initial time  
          //   initial_pop: 2000,         // Initial total population  
          //   duration_t: 18,    // Time over which to model (in months)  
          //   h: 1,             // Step size for Runge Kutta method (1 recommended)  
          //   k: 10000,         // Carrying capacity of environment  
          //   prop_female: 0.5,         // Proportion of population that are female (0-1)  
          //   adult_surv: 0.99,        // Adult survivability rate (0-1)  
          //   infant_surv: 0.4,         // Infant survivability rate (0-1)  
          //   l: 6,             // Average litter size  
          //   avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          //   initial_ster_prop: 0,            // Initial number of sterilised dogs  
          //   m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          //   n_s: 250,         // Number of sterilisations taking place each month  
          // },
          // {
          //   desired_S_N: 0.5,    // Proportion of sterilized dogs
          //   t0: 0,            // Initial time  
          //   initial_pop: 2000,         // Initial total population  
          //   duration_t: 18,    // Time over which to model (in months)  
          //   h: 1,             // Step size for Runge Kutta method (1 recommended)  
          //   k: 10000,         // Carrying capacity of environment  
          //   prop_female: 0.5,         // Proportion of population that are female (0-1)  
          //   adult_surv: 0.99,        // Adult survivability rate (0-1)  
          //   infant_surv: 0.4,         // Infant survivability rate (0-1)  
          //   l: 6,             // Average litter size  
          //   avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          //   initial_ster_prop: 0,            // Initial number of sterilised dogs  
          //   m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          //   n_s: 250,         // Number of sterilisations taking place each month  
          // }
        ]
        // N_t               // Total population at time t  
        // S_t               // Number of sterilised dogs at time t
}

const ProtectedAppWrapper = () => {
    const theme = createTheme({
        palette: {
          primary: {
            main: primary.neutral,
          },
          secondary: {
            main: secondary.neutral,
          },
        },
      });
      
      
    const {isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently} = useAuth0();
    
    const makeAuthenticatedRequest = async (url, options = {}) => {
        if (!isAuthenticated) {
            throw new Error("User is not authenticated");
        }
        try {
            const token = await getAccessTokenSilently();  // Wait for the token
            const response = await axios(url, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            }
            });
            
            console.log("RESPONSE", response);
            return response;  // Return the full response
        } catch (error) {
            console.error("Request failed", error);
            throw new Error("Request failed", error);  // Throw an error if it fails
        }
        };
    
    return (
        <ThemeProvider theme={theme}>

            <Box
                height={'100%'} 
            >
                { isAuthenticated ? 
                    <DocumentManagementProvider
                        appSlug={'dogpop-model'}
                        onFormatDocTitle={(docTitle) => {
                            return `${docTitle} - Dog Population Modeling Tool`
                        }}
                        serviceApiBase={process.env.REACT_APP_HUB_API_URL}
                        onMakeDocumentURL={(service, document_id)=>{
                            return `${service}?d=${document_id}`
                        }}
                        documentsPath={'/api/services/documents/'}
                        onMakeAuthenticatedRequest={makeAuthenticatedRequest}
                        defaultDocument={defaultDocDetails}
                        defaultDocumentState={defaultDocState}

                        maxFilenameLength={69}
                        /*
                            In dependent service providers, use this:
                            
                            onDocumentChanged={(doc) => {
                                console.log("Document changed", doc)
                            }}
                         */
                    >
                        <ChartServiceProvider
                            // onUpdateVars={ (vars) => setActiveDocumentState(vars) }
                            serviceApiBase={process.env.REACT_APP_DOGPOP_API_URL}
                            initialState={defaultDocState}
                        >
                            <App/>
                        </ChartServiceProvider>
                    </DocumentManagementProvider>
                    :
                    <Box 
                        display={'flex'} 
                        height={'100%'} 
                        width={'100%'}
                        alignContent={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        justifyItems={"center"}
                    >
                        {isLoading ? 

                            <CircularProgress/>
                            
                            :
                            <Container sx={{maxWidth: 'md'}}>
                                <Card sx={{display:'flex', flexDirection: 'row'}}>
                                    <CardMedia
                                        component="img"
                                        media="picture"
                                        alt="Contemplative Reptile"
                                        sx={{display:'block', position:'relative', minWidth:'100px', maxWidth:'5%', height:'500px'}}
                                        image={image}
                                    
                                    />
                                    <CardContent sx={{flex:1, display:"flex", alignContent:'center', alignItems:'center',justifyContent:'center', justifyItems:'center'}}>
                                        <Stack spacing={2}>
                                            <Typography variant="h3">Sign In</Typography>
                                            <Typography variant="subtitle2">You've stumbled on a private application for our trusted partners.</Typography>

                                            <Button size="large" onClick={()=>loginWithRedirect()} variant="contained">Sign In</Button>
                                            <Button variant="text">Learn More & Sign Up</Button>
                                        </Stack>
                                        
                                    </CardContent>
                                </Card>

                            </Container>
                        
                        }
                    </Box>
                }
            </Box>
        </ThemeProvider>
    )
}

export default ProtectedAppWrapper;