export const initialChart = {

}

export const intervention_variables = (type, variables) => {
    switch(type) {
        case 'find_monthly_sters':
            return {
                type: "find_monthly_sters",
                intervention_start: 2,
                desired_ster_prop: 0.8,     //Lower limit 0, upper limit 1, continuous
                intervention_duration: 3    
            }

        case 'known':
            return {
                type: "known",
                intervention_start: 2,      // Lower limit 0, upper limit duration_t, discrete
                monthly_sters: 100,         
                intervention_duration: 3    // Lower limit 1, upper limit (duration_t - intervention_start), discrete
            }
        case 'find_duration':
            return {
                type: "find_duration",
                intervention_start: 2,
                desired_ster_prop: 0.8,
                monthly_sters: 100
            }
    }
}

export const intervention_types = [
    'find_monthly_sters', 'known', 'find_duration'
]

export const variableSet = {

    initial: {
        // desired_S_N: {
        //     label: "Desired Proportion of Sterilized Dogs",
        //     min: (variables) => 0,
        //     max: (variables) => 1,
        // },
        // start_month: {
        //     type: "int",
        //     label: "Initial time",
        //     min: (variables) => 0,
        //     max: (variables) => variables.duration_t ? variables.duration_t - 2 : 32, //variables.duration_t - 2,
        //     stepSize: 1,
        // },
        duration_t: {
            type: "int",
            label: "Model duration in months",
            min: (variables) => variables.start_month ? variables.start_month + 1 : 1,
            max: (variables) => 36,
            stepSize: 1,
        },
        initial_pop: {
            type: "int",
            label: "Initial total population",
            min: (variables) => 0,
            max: (variables) => variables.carry_cap ? variables.carry_cap : 50000,//variables.k,
            stepSize: 10,
        },
        // step_size: {
        //     type: "int",
        //     label: "Step size for Runge Kutta method",
        //     min: (variables) => 1,
        //     max: (variables) => 5,
        //     stepSize: 1,
        // },
        carry_cap: {
            type: "int",
            label: "Environment's carrying capacity",
            min: (variables) => 1000,
            max: (variables) => 50000,
            stepSize: 1000,
        },
        prop_female: {
            type: "float",
            label: "Proportion of females",
            min: (variables) => 0,
            max: (variables) => 1,
            stepSize: 0.05,
        },
        adult_surv: {
            type: "float",
            label: "Adult survivability rate",
            min: (variables) => 0,
            max: (variables) => 1,
            stepSize: 0.05,
        },
        infant_surv: {
            type: "float",
            label: "Infant survivability rate",
            min: (variables) => 0,
            max: (variables) => 1,
            stepSize: 0.05,
        },
        avg_litter_size: {
            type: "float",
            label: "Average litter size",
            min: (variables) => 1,
            max: (variables) => 10,
            stepSize: 0.25,
        },
        avg_litters_yearly: {
            type: "int",
            label: "Reproduction rate/average number of litters",
            min: (variables) => 0,
            max: (variables) => 10,
            stepSize: 1,
        },
        initial_ster_prop: {
            type: "int",
            label: "Initial Target Proportion of Sterilized Dogs",
            min: (variables) => 0,
            max: (variables) => 1,
            stepSize: 0.05,
        },
        net_migration_prop: {
            type: "float",
            label: "Net migration rate (positive into environment, negative out)",
            min: (variables) => -1,
            max: (variables) => 1,
            stepSize: 0.01,
        },
        monthly_sters: {
            type: "int",
            label: "Number of sterilizations taking place each month",
            min: (variables) => 0,
            max: (variables) => 500,
            stepSize: 2,
        },
        // desired_ster_prop:  {
        //     label: "Desired Sterilization Proportion",
        //     type: 'float',
        //     min: function(variables) { return 0 },
        //     max: function(variables) {
        //         return 1
        //     }
        // }
    },
    intervention: { // Loop through keys as the results for the popup button after clicking

        known: {
            // {intervention_start, monthly_sters, intervention_duration}
            intervention_start: {
                label: "Start Interval",
                type: 'int',
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return variables?.initial?.duration_t ? variables.initial.duration_t - intervention.intervention_duration : 69
                },
                stepSize: 1,
            },

            monthly_sters: {
                label: "Monthly Sterilizations",
                type: "int",
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return 10000
                },
                stepSize: 10,
            },
            intervention_duration: {
                label: "Intervention Duration",
                continuous: false,
                chunk: 1,
                min: function(variables, intervention) { return 1 },
                max: function(variables, intervention) {
                    return variables.initial.duration_t - intervention.intervention_start
                },
                stepSize: 1,
            },
    
        }, 

        find_monthly_sters: {
            // {intervention_start, desired_ster_prop, intervention_duration}
            intervention_start: {
                label: "Start Interval",
                type: 'int',
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return variables.initial.duration_t - intervention.intervention_duration
                },
                stepSize: 1,
            },
            desired_ster_prop:  {
                label: "Desired Sterilization Proportion",
                type: 'float',
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return 1
                },
                stepSize: 0.1,
            },
            intervention_duration: {
                label: "Intervention Duration",
                continuous: false,
                chunk: 1,
                min: function(variables, intervention) { return 1 },
                max: function(variables, intervention) {
                    return variables ? 
                        variables.initial.duration_t
                        : 100
                },
                stepSize: 1,
            },


        },
        find_duration: {
            // {intervention_start, desired_ster_prop, monthly_sters}   
            intervention_start: {
                label: "Start Interval",
                type: 'int',
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    console.log(">>>>> RECALCULATING MAX: ", variables.initial.duration_t - intervention.intervention_duration)
                    return variables.initial.duration_t - intervention.intervention_duration
                },
                stepSize: 1,
            },

            desired_ster_prop:  {

                label: "Desired Sterilization Proportion",
                type: 'float',
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return 1
                },
                stepSize: 0.1,
            },
            
            monthly_sters: {
                label: "Monthly Sterilizations",
                min: function(variables, intervention) { return 0 },
                max: function(variables, intervention) {
                    return 10000
                },
                stepSize: 1,
            },

        },

    }


}