// Make the chart usable on the frontend
export const PopModelChartData = (values) => {
    const {t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s} = values;
    
    const chart = rungeKutta(t0, initial_pop, duration_t, h, k, clamp(prop_female, 0, 1), adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s)
    return {
      x: chart[1],
      c1: chart[2],
      c2: chart[3],
      c3: chart[4],
    };
}

export const convert_to_title = (type) => {
  const words = type.split('_');
  const capitalized = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalized.join(' ');
}


/* Helper to generate the content needed for a new intervention block 

  * TODO: 
    - 

*/
export const interventionContentByType = (type, variables) => {

  /* interventionStart:
    min: variables.start_month or variables.interventions[lastIndex].start_month
    max: variables.duration_t
  */
  const index = Math.max(variables.interventions.length - 1, 0);
  
  //
  const interventionStart = 0;//Math.min(variables.initial.start_month, last_intervention_start);


  switch(type) {
    case 'find_monthly_sters':
      return {
        type: "find_monthly_sters",
        intervention_start: interventionStart,
        desired_ster_prop: 0.8,     //Lower limit, upper limit, continuous
        intervention_duration: 3    
      }
    case 'known':
      return {
        type: "known",
        intervention_start: interventionStart,      // Lower limit 0, upper limit duration_t, discrete
        monthly_sters: 100,         
        intervention_duration: 3    // Lower limit 1, upper limit (duration_t - intervention_start), discrete
      }
    case 'find_duration':
      return {
        type: "find_duration",
        intervention_start: interventionStart,
        desired_ster_prop: 0.8,
        monthly_sters: 100
      }
  }
}

export const FinalValueSterilizationGrowth = (values) => {
    const {t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s, desired_S_N} = values;

    const  result = growthBinarySearchNS(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, desired_S_N)
    return result;
}

export const FinalValueSterilizationMaintenance = (values) => {
    const {t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s, desired_S_N} = values;

    const  result = maintenanceBinarySearchNS(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, desired_S_N)
    return result;

}

export const clamp = (num, min, max) => {
    return num <= min 
      ? min 
      : num >= max 
        ? max 
        : num
}

// Graph functions adapted to JS from https://github.com/halexmmond/happy_doggo_population_sterilisation_modelling/blob/master/equation_functions.py
// Helper function for calculating the number of sterilized dogs
const numSterilizedDogs = (initial_ster_prop, adult_surv, m, n_s, t) => {
    const S_t = ((n_s + ((m + adult_surv - 1) * initial_ster_prop)) * t) + initial_ster_prop;
    return S_t;
  };
  
  // Differential equation for the total population of stray dogs
  const dNdt = (N_t, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, n_s, t, m) => {
    const dN_dt = N_t * ((k - N_t) / k) * (
      (prop_female * adult_surv * infant_surv * l * avg_litters_yearly * (1 - ((n_s + ((m + adult_surv - 1) * initial_ster_prop)) * t) / N_t)) - (1 - adult_surv) + m
    );
    return dN_dt;
  };
  
  // Runge-Kutta method for solving the differential equation
  const rungeKutta = (t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s) => {
    let t = t0;
    let N_t = initial_pop;
    const t_values = [t0];
    const N_t_values = [initial_pop];
    const S_t_values = [initial_ster_prop];
    const S_N_values = [(initial_ster_prop / initial_pop) * 100];
  
    while (t < duration_t + 1) {
      const k1 = h * dNdt(N_t, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, n_s, t, m);
      const k2 = h * dNdt(N_t + 0.5 * k1, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, n_s, t + 0.5 * h, m);
      const k3 = h * dNdt(N_t + 0.5 * k2, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, n_s, t + 0.5 * h, m);
      const k4 = h * dNdt(N_t + k3, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, n_s, t + h, m);
  
      N_t += (k1 + 2 * k2 + 2 * k3 + k4) / 6;
  
      t += h;
  
      let S_t = numSterilizedDogs(initial_ster_prop, adult_surv, m, n_s, t);
  
      if (S_t > N_t) {
        S_t = N_t;
      }
  
      t_values.push(t);
      N_t_values.push(N_t);
      S_t_values.push(S_t);
      S_N_values.push((S_t / N_t) * 100);
  
      if (Math.abs(t - duration_t) < 0.01) {
        break;
      }
    }
  
    return [Math.ceil(N_t), t_values, N_t_values, S_t_values, S_N_values];
  };
  
  // Function for binary search to find n_s that maintains a certain sterilization ratio

//   /** Question... is this used/needed? */
//   const binarySearchNS = (t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, desired_S_N) => {
//     let lower_limit = 0;
//     let upper_limit = 2000;
//     let n_s;
  
//     while (upper_limit - lower_limit > 1) {
//       n_s = Math.ceil((upper_limit + lower_limit) / 2);
//       const S_t = numSterilizedDogs(initial_ster_prop, adult_surv, m, n_s, duration_t);
  
//       const [N_t] = rungeKutta(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s);
//       const S_N = S_t / N_t;
  
//       if (S_N < desired_S_N) {
//         lower_limit = n_s;
//       } else {
//         upper_limit = n_s;
//       }
//     }
  
//     return n_s + 1;
//   };

  function growthBinarySearchNS(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, desired_S_N) {
    let lowerLimit = 0;
    let upperLimit = 2000;
    let n_s;

    while (upperLimit - lowerLimit > 1) {
        n_s = Math.ceil((upperLimit + lowerLimit) / 2);

        // Assuming numSterilizedDogs is a previously defined function that calculates S(t)
        const S_t = numSterilizedDogs(initial_ster_prop, adult_surv, m, n_s, duration_t);

        // Assuming rungeKutta is a previously defined function that calculates N(t)
        let [N_t] = rungeKutta(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s);
        if (N_t < 0) {
            N_t = 1; // Correct negative population, if any, to avoid division by zero later
        }

        let S_N = S_t / N_t; // Calculate sterilization proportion

        if (S_N < desired_S_N) {
            lowerLimit = n_s;
        } else {
            upperLimit = n_s;
        }
        
    }

    return n_s + 1;
}


function maintenanceBinarySearchNS(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m) {
    let lowerLimit = 0;
    let upperLimit = 2000;
    let n_s;

    while (upperLimit - lowerLimit > 1) {
        n_s = Math.ceil((upperLimit + lowerLimit) / 2);

        // Assuming dSNdt is a previously defined function
        const dSN_dt = dSNdt(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s);
        

        if (dSN_dt < 0) {
            lowerLimit = n_s;
        } else {
            upperLimit = n_s;
        }
    }

    return n_s + 1;
}


function dSNdt(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s) {
    const S_t = ((n_s + ((m + adult_surv - 1) * initial_ster_prop)) * duration_t) + initial_ster_prop;
    const dS_dt = n_s + ((m + adult_surv - 1) * initial_ster_prop);
  
    // Assuming rungeKutta returns an array where the first element is N_t
    const [N_t] = rungeKutta(t0, initial_pop, duration_t, h, k, prop_female, adult_surv, infant_surv, l, avg_litters_yearly, initial_ster_prop, m, n_s);
  
    const dN_dt = N_t * ((k - N_t) / k) * (
      (prop_female * adult_surv * infant_surv * l * avg_litters_yearly * (1 - (((n_s + ((m + adult_surv - 1) * initial_ster_prop)) * duration_t) / N_t))) - (1 - adult_surv) + m
    );
  
    const dSN_dt = ((N_t * dS_dt) - (S_t * dN_dt)) / (N_t ** 2);
  
    return dSN_dt;
  }
  

  // Setting values for things:
  export const ensureFloat = (key, value) => {
    if(value == null) {
      return 0;
    }
    let num = parseFloat(value);
    if (isNaN(num)) {
      throw new Error(`Cannot convert value ${key}:${value} to Float`);
    }
    return num;
  }
  
  export const ensureInt = (key, value) => {
    if (value == 0) return 0;
        let num = parseInt(value);
        
    if (isNaN(num)) {
      throw new Error(`Cannot convert value ${key}:${value} to Integer`);
    }
    return num;
  
  }
  
  /* Recursive function to check changes when the document is altered

      Examples usage:

      const savedData = useRef(data || {});
      const dataChanged = objectEquals(data, savedData.current);
      useState( () => {
        ...
      }, [dataChanged])

      Found here: https://stackoverflow.com/questions/69213754/react-useeffect-json-object-with-same-content-but-disordered-fields

      Used to detect document changes in ChartServiceProvider variables to feed an update back to DocumentManagementContext

   */
  const countProps = (obj) => {
    return Object.keys(obj).length
  }
  export const objectEquals = (o1, o2) => {

      if (typeof(o1) !== typeof(o2)) {
          return false;
      }
  
      if (typeof(o1) === "function") {
          return o1.toString() === o2.toString();
      }
  
      if (o1 instanceof Object && o2 instanceof Object) {
          if (countProps(o1) !== countProps(o2)) {
              return false;
          }
          var r = true;
          Object.keys(o1).foreach(k => {
            r = objectEquals(o1[k], o2[k]);
            if (!r) {
                return false;
            }
          })
          return true;
      } else {
          return o1 === o2;
    }
  }