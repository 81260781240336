import * as React from "react";
import { Modal, Box, Typography, Paper, Fade, IconButton, Divider, CardHeader, Card, CardContent, useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function AnimatedModal({ open, contentStyle={}, onClose, children, title="", width=400, maxWidth='auto', minWidth='auto', p=1 }) {
  const theme = useTheme();
  const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    minWidth: minWidth,
    maxWidth: maxWidth,
    transform: "translate(-50%, -50%)",
    width: bigEnough ? width : 340,
    outline:"none",
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: p,
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{
        overflow:'scroll',
        height:'100%',
        display:'block'
      }}
    >
        <Fade in={open}>
          <Card sx={style}>
            <CardHeader
              title={title}
              action={
                <IconButton onClick={onClose} aria-label="close">
                  <Close />
                </IconButton>
              }
      
            >

            </CardHeader>
            <CardContent sx={{...contentStyle}}>
              {children}
            </CardContent>
          </Card>
        </Fade>
    </Modal>
  );
}
export default AnimatedModal;
