import React, { createContext, useState, useContext } from 'react';

// Create the SettingsContext
const SettingsContext = createContext();

// Create a provider component
export const SettingsProvider = ({ children }) => {
  // This state will hold the settings
  const [settings, setSettings] = useState({
    showVariableKeys: false,
    showPreviousGraph: false
  });

  // Function to update a specific setting
  const setSetting = (key, value) => {
    console.log(key,value);
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  return (
    <SettingsContext.Provider value={{ settings, setSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

// Custom hook to use the settings context
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};